@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

:root {
  --primary-menu-text: #333333;
  --primary-services-background: #f4f4f4;
  /*#1070fc is the text color of the logo*/
  --primary-navigation: #1070fc;
  --primary-menu-background: #f6f7f8;
  --primary-button: #ef4035;
  --primary-hover-button: #f33501;
  --primary-white: #ffffff;
  --primary-black: #070707;
  --primary-transparent-black: rgb(0,0,0,0.7);
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
}

body {
  font-family: "Roboto Condensed" sans-serif !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*********************NAVBAR*********************************/
nav {
  z-index: 2;
}

.logo {
  width: 16.25rem;
}

/*******************MENU LINKS*******************************/
nav a.nav-link {
  font-size: 0.8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem; 
  color: var(--primary-menu-text) !important;
  border-top: 0.1875rem solid var(--primary-menu-background);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 10 !important;
}

@media(max-width: 768px) {
  .navbar-brand {
    width: 7rem !important;
  }
}

.navbar-nav > li > a.active, .navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid var(--primary-navigation);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-navigation);
}


/*********************HEADER*********************************/

.header-wraper {
  position: relative;
  background: url(background.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-menu-text);
  text-transform: uppercase;
}

/*********************TYPED TEXT*********************************/
.typed-text {
  font-size: 2rem;
  color: var(--primary-menu-text);
}

@media(max-width: 768px) {
  .main-info h1{
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/*********************HEADER MAIN OFFER BUTTON*********************************/

.btn-main-offer {
  border: 2px solid var(--primary-navigation);
  text-transform: uppercase;
  border-radius: 0;
  padding: .625rem 0.75rem 0.75rem 0.75rem;
  color:var(--primary-navigation);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-navigation);
  color: var(--primary-menu-background);
  transition: .2s ease-in-out;
}

/*********************PARTICLES*********************************/

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px){
  .particles-canvas {
    display: none;
  }
}

/*********************ABOUT*********************************/

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-img {
  width: 20rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid var(--primary-navigation);
}

.about-heading {
  color: var(--primary-menu-text);
  text-transform: uppercase;
}

@media(max-width: 468px) {
  .about-img {
    width: 10rem;
  }
}

/*********************SERVICES*********************************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-services-background);
}

.services h1 {
  color: var(--primary-menu-text);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-navigation);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-menu-background);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-navigation);
  padding: 0.675rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: var(--primary-navigation);
  border-bottom: 0.3125rem solid var(--primary-menu-text);
}

.services .box:hover .icon {
  color: var(--primary-menu-text);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

.services .box:hover h3 {
  color: var(--primary-white);
}

/*********************ROADMAP*********************************/

.roadmap {
  padding: 0.7rem;
}

.roadmap-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.roadmap h1 {
  text-transform: uppercase;
  color: var(--primary-menu-text);
}

.roadmap-wrapper:before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-menu-text);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-menu-text);
  background-color: var(--primary-navigation);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-menu-text);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-navigation);
  font-weight: 400;
}

/*-------MEDIA---------*/
@media(max-width: 768px) {
  .roadmap .container {
    padding-left: 0;
  }
  .roadmap-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

/*********************PRODUCTS*********************************/
.products-wrapper {
  background: var(--primary-menu-background);
  padding: 3rem 0;
}

.products-wrapper h1 {
  color: var(--primary-navigation);
}

.products-image {
  width: 15rem;
  border: 1px solid var(--primary-navigation);
  padding: 0 0.5rem;
}

.products-image-box {
  position: relative;
  margin: 1rem;
}

/*********************OVERFLOW BOX*********************************/
.overflow {
  position: absolute;
   opacity: 0;
   background: var(--primary-black);
   width: 15rem;
   height: 8rem;
   top: 0;
  border: 1px solid var(--primary-hover-button);
  cursor: pointer;
}

.products-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.products-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--primary-navigation);
  font-size: 1.5rem;
}

.products-image-box:hover .products-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}


/********************* OVERFLOW BOX *********************************/
.products-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-navigation);
}

.hyper-link:hover {
  color: var(--primary-hover-button);
}

/********************* MEDIA *********************************/

@media(max-width: 768px) {
  .products-image-popupbox {
    width: 100%;
  }
}

@media(max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }
  .products-image-popupbox {
    width: 40%;
  }
}

@media(max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }
  .products-image-popupbox {
    width: 30%;
  }
}

/********************* CUSTOMERS *********************************/
.customers {
  background-image: url(customers-bg.jpg);
  background-size: cover;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
}

.customers h1 {
  color: var(--primary-navigation);
  padding: 3rem 0;
  text-transform: uppercase;
}

.customers-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: var(--primary-transparent-black);
}

.myCarousel {
  color: var(--primary-white);
  background: transparent;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 17.875rem;
}

.myCarousel h3 {
  color: var(--primary-navigation);
  font-weight: 600;
  text-transform: uppercase;
}

.myCarousel p {
  color: var(--primary-grey);
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}

/********************* CONTACT *********************************/
.contact {
  background: var(--primary-grey);
  padding: 3rem;
}

.contact h1 {
  color: var(--primary-navigation);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-menu-text);
  font-size: 1.2rem;
}

/********************* INPUTS *********************************/
.contact input, .contact textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 2px solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-navigation);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contact .container {
  padding: 4rem 0;
}

.contact input:focus, .contact textarea:focus {
  background: transparent;
  color: var(--primary-navigation);
  border-bottom: 2px solid var(--primary-dark-grey);
}

.contact textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-navigation);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-hover-button);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-navigation);
}

/********************* MEDIA *********************************/
@media(max-width: 768px) {
  .contact {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contact p {
    padding: 0 0.4rem;
  }
}

/********************* FOOTER *********************************/
.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-navigation);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}